import React from 'react'
import { Field } from 'redux-form'

class Input extends React.Component {

    renderError = ({error, touched}) => {
		if(error && touched){
			return(
				<div className="bc_form-error-msg">
					{error}
				</div>
			)
		}
    }

    renderTextarea = ({input, label, meta}) => {
        return (
            <div className={`bc_form-controll ${meta.error && meta.touched ? 'bc_error' : ''}`}>
                <textarea {...input} autoComplete="off" placeholder={label}></textarea>
                {this.renderError(meta)}
            </div>
        )
    }
    
    renderSelect = ({input, label, meta}) => {
        return (
            <div className={`bc_form-controll ${meta.error && meta.touched ? 'bc_error' : ''}`}>
                <select {...input} type={this.props.type} autoComplete="off" placeholder={label}>
                    {this.props.children}
                </select>
                {this.renderError(meta)}
            </div>
        )
    }

    renderInput = ({input, label, meta}) => {
        return (
            <div className={`bc_form-controll ${meta.error && meta.touched ? 'bc_error' : ''}`}>
                <input {...input} type={this.props.type} autoComplete="off" placeholder={label} />
                {this.renderError(meta)}
            </div>
        )
    }

    render(){
        if (this.props.type === 'select'){
            return <Field name={this.props.name} component={this.renderSelect} label={this.props.label} />            
        } else if (this.props.type === 'textarea') {
            return <Field name={this.props.name} component={this.renderTextarea} label={this.props.label} />
        }
        return <Field name={this.props.name} component={this.renderInput} label={this.props.label} />
    }
    
}

export default Input