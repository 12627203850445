import React from 'react'
import {
    Container,
    Row,
    Grid
} from '../../components/Grid'
import { connect } from 'react-redux'
import { 
    fetchCategoriesWithOneProduct,
    fetchBrands
} from '../../actions'
import history from '../../history'
import {Redirect} from 'react-router-dom'

class Filter extends React.Component {

    componentDidMount(){
        this.props.fetchCategoriesWithOneProduct()
        this.props.fetchBrands()
    }

    filterViaCategory = e => {
        if(e.target.value !== ''){
            history.push(`/products/${e.target.value}`)
        } else {
            history.push('/products')
        }
        
    }

    filterViaBrand = e => {
        if(e.target.value !== ''){
            history.push(`/${e.target.value}/products`)
        } else {
            history.push('/brands')
        }
        
    }

    renderCategoryList = () => {
        return this.props.categories.map((item, index) => {
            return (
                <option value={item.search_name} key={index}>{item.title}</option>
            )
        })
    }

    renderBrandList = () => {
        return this.props.brands.map((item, index) => {
            return (
                <option value={item.search_name} key={index}>{item.title}</option>
            )
        })
    }

    render(){
        return(
            <Container className="bc_filter-container">
                <Row>
                    <Grid>
                        <div className="bc_filter-inner">
                            <p>Sort By: </p>
                            <select className="bc_form-control" onChange={this.filterViaCategory}>
                                <option value="">select category</option>
                                {this.renderCategoryList()}
                            </select>
                            <select className="bc_form-control" onChange={this.filterViaBrand}>
                                <option value="">select brand</option>
                                {this.renderBrandList()}
                            </select>
                        </div>
                        
                    </Grid>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        categories: Object.values(state.categoriesState),
        brands: Object.values(state.brandsState)
    }
}

export default connect(mapStateToProps, { 
    fetchCategoriesWithOneProduct,
    fetchBrands
})(Filter)