import React from 'react'

class ButtonWhite extends React.Component {
	render(){
		return (
			<button 
				className={`bc_button_white ${this.props.className ? this.props.className : this.props.className}`} 
                onClick={this.props.onClick}
			> 	
                {this.props.text} {this.props.icon ? <img src={this.props.icon} alt="" /> : ''}
			</button>
		)
	}
}

export default ButtonWhite