import React from 'react'
import Carousel from '../../components/SlickSlider/Carousel'
import {
    Section,
    Container,
} from '../../components/Grid'
import SectionHeading from '../../components/Content/SectionHeading.js'
import { connect } from 'react-redux'
import { fetchCategory } from '../../actions'
import { BASE_URL } from '../../paths'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'

import LazyLoad from 'react-lazyload';

class ProductCarousel extends React.Component {

    componentDidMount(){
        this.props.fetchCategory(this.props.searchName)
    }

    renderList(){
        return this.props.data.map((item, index) => {
            if(item.brand){
                return(
                    <div className="bc_item" key={index}>
                        <Link to={`/${item.brand.search_name}/${this.props.searchName}`}>
                            <ImageScaleOnHover>
                                {
                                    index > 3 && (
                                        <LazyLoad>
                                            <figure>
                                                <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" />
                                            </figure>
                                        </LazyLoad>
                                    )
                                }
                                {
                                    index <= 3 && (
                                        <figure>
                                            <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" />
                                        </figure>
                                    )
                                }
                                
                            </ImageScaleOnHover>
                            
                            <div className="bc_bottom-title">
                                <h4>
                                    <div><span style={{textTransform: 'upperCase'}}>{item.brand.title}</span> {item.title}</div>
                                </h4>
                            </div>
                        </Link>                    
                    </div>
                )
            }
        })
    }

    render(){
        if(_.isEmpty(this.props.data)){
            return <></>
        }
        
        return(
            <Section className="bc_slider-section bc_less-height bc_section-home-products">
                <Container>
                    <SectionHeading text={this.props.title} link={this.props.link ? this.props.link : null} />
                    <Carousel>
                        {this.renderList()}
                    </Carousel>
                </Container>
            </Section>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.categoryState[ownProps.searchName]
    }
}

export default connect(mapStateToProps, {
    fetchCategory
})(ProductCarousel)