import {
    PASSWORD_FIND,
    PASSWORD_FIND_ERROR
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case PASSWORD_FIND:
            return {
                success: action.payload
            }
        case PASSWORD_FIND_ERROR:
            return {
                error: action.payload.message
            }
        default:
            return state
    }
}