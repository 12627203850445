import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import SmallHeading from '../../components/Content/SmallHeading'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'

class TermsAndConditions extends React.Component {

    

    renderPageContent(){
        return(
            <React.Fragment>
                <SmallHeading heading="Terms & Conditions" />
                <Section className="bc_terms-section">
                    <Container>
                        <Row>
                            <Grid col="12">

                                <h1>CAPE PLUMBING & BATHROOMS – HANSGROHE COMPETITION -  T’s & C’s</h1>
                                <ol>
                                    <li>HANSGROHE COMPETITION – Instagram Competition</li>
                                    <li>This competition is open to all residents of South Africa who are aged 18 years or older.</li>
                                    <li>The promoter is Cape Plumbing & Bathrooms, hereinafter referred to as “the Promoter”.</li>
                                    <li>Excluded from entering this competition are employees, directors, members, consultants and partners of the advertising agency or agents of the Promoter, and their spouses, life partners, immediate family, business partners or associates.</li>
                                    <li>There is 1 prize for this competition.</li>
                                    <li>The competition runs from 06 February 2023 to 28 February 2023. </li>
                                    <li>To enter this competition, participants must do the following:<br />
                                        <ul>
                                            <li>Follow the Cape Plumbing & Bathrooms Instagram page: @cpandb_za</li>
                                            <li>Comment and share your ideal showering experience</li>
                                        </ul>
                                    </li>
                                    <li>The prize cannot be transferred or exchanged for its cash value.</li>
                                    <li>Valid and correct entries for the competition post will be entered into a Random Online Name Picker to choose the WINNER. </li>
                                    <li>The WINNER will be selected via a Random Online Name Picker after the close of the competition (28 February 2023) and informed accordingly via an Instagram DM.</li>
                                    <li>The prize consists of (1) hansgrohe Vernis Blend Overhead Shower 200 1 jet Shower Rose valued at R2300. Installation, waste kits and plumbing are not included in the prize and is done at the WINNERS own cost and risk. </li>
                                    <li>The WINNER will be announced in the Comments section of the Competition Post on Instagram after Competition documentation has been approved and finalised.</li>
                                    <li>The Promoter will make every reasonable attempt to contact the WINNER. Should we be unable to contact the WINNER, we reserve the right to draw a new WINNER.</li>
                                    <li>The WINNER will need to provide proof of identity (SA ID card or Drivers Licence), date of birth,  full name, mobile phone number, email address and a day-time delivery address, to redeem the prize.</li>
                                    <li>Prize may take up to three weeks to be delivered after the competition closing documents have been finalised. There is no delivery charge to the WINNER.</li>
                                    <li>By entering the competition and/or accepting the prize, the WINNER hereby indemnifies, releases and holds harmless Cape Plumbing & Bathrooms and others, from and against any actions, claims, liability for injury, loss, damage of any kind resulting from the competition and/or prize.</li>
                                    <li>The Promoter’s decision is final, and no correspondence will be entered into.</li>
                                    <li>By entering this competition, you agree to give the Promoter and its group of companies permission to keep all material and information submitted as part of your entry, on electronic or hard copy databases and filing systems for the above-mentioned competition and by entering this competition, you agree to the Promoter transferring your data to our promotional partners to enable the awarding of the winning prize.</li>
                                    <li>Entry instructions are deemed to form part of the Terms and Conditions and by entering this competition all participants will be deemed to have accepted and be bound by the Terms and Conditions. Please retain a copy for your information.</li>
                                    <li>Official Rules</li>
                                    <li>This promotion is in no way sponsored, endorsed or administered by, or associated with, Facebook or Instagram. You understand that you are providing your information to Cape Plumbing & Bathrooms and not to Facebook or Instagram. The information you provide will be used to conduct this promotion and as consented to by you.</li>
                                </ol>
                                <p>PROMOTER: Cape Plumbing & Bathrooms</p>
                                <br />
                            </Grid> 
                        </Row>   
                    </Container>    
                </Section>  
                
            </React.Fragment>
        )
    }

    render() {

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Bathroom Collective",
            "alternateName": "Bathroom Collective",
            "url": "https://bathroomcollective.co.za/",
            "logo": "https://bathroomcollective.co.za/facebook-share.jpg",
            "sameAs": [
              "https://www.facebook.com/Bathroom-Collective-100911267955966/",
              "https://www.instagram.com/bathroom_collective/",
              "http://www.pinterest.com/bathroom_collective"
            ]
          }
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Bathroom Collective | Terms & Conditions</title>
                    <meta name="description" content="BATHROOM COLLECTIVE – VICTORIA + ALBERT VETRALLA COMPETITION - T’s & C’s" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

export default TermsAndConditions