import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { 
    fetchBlog
} from '../../actions'
import Nav from '../../components/Nav/Nav'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import TopBanner from '../../components/Banner/TopBanner'
import { BASE_URL } from '../../paths'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'

class Home extends React.Component {

    componentDidMount(){
        this.props.fetchBlog(this.props.match.params.searchName)        
    }

    renderGallery(){
        return this.props.blog.gallery.map((item, index) => {
            return (
                <Grid col="4" key={index} className="bc_item">
                    <img src={`${BASE_URL}${item.image_name}`} alt="" />
                </Grid>
            )
        })
    }

    renderPageContent(){
        return(
            <React.Fragment>
                <OnScrollAnimation>
                    <TopBanner 
                        img={`${BASE_URL}${this.props.blog.image}`}
                        heading={this.props.blog.description} 
                        subHeading={this.props.blog.title}
                    />
                </OnScrollAnimation>   

                <OnScrollAnimation>
                    <Section className="bc_blog-section">
                        <Container>
                            <Row>
                                <Grid col="6">
                                    {/* <p>{this.props.blog.body}</p>    */}
                                    <article dangerouslySetInnerHTML={{ __html: this.props.blog.body }}></article> 
                                </Grid> 
                            </Row> 
                            <Row>
                                {this.props.blog.gallery && this.renderGallery()}
                                {
                                    this.props.blog.body_2 && 
                                    <Grid>
                                        {/* <p>{this.props.blog.body_2}</p>   */}
                                        <article dangerouslySetInnerHTML={{ __html: this.props.blog.body_2 }}></article>  
                                    </Grid>
                                }
                                
                            </Row>    
                        </Container>    
                    </Section>    
                </OnScrollAnimation>  
            </React.Fragment>
        )
    }

    render() {

        const ldJson = {
          "@context": "https://schema.org",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": window.location.href
          },
          "headline": this.props.blog ? this.props.blog.title : '',
          "description": this.props.blog ? this.props.blog.description : '',
          "image": this.props.blog ? BASE_URL + this.props.blog.image_preview : '',  
          "author": {
            "@type": "Organization",
            "name": "Optimal Online"
          },  
          "publisher": {
            "@type": "Organization",
            "name": "Optimal Online",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.optimalonline.co.za/images/optimal-online-logo.png"
            }
          }
        }
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.blog && this.props.blog.title + ' - '} Bathroom Collective</title>
                    <meta name="description" content={this.props.blog && this.props.blog.description} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <Nav>{this.props.blog && this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        blog: state.blogsState[ownProps.match.params.searchName]
    }
}

export default connect(mapStateToProps, {
    fetchBlog
})(Home)