import {
    CREATE_ORDER_AND_UPDATE_BILLING,
    CREATE_ORDER_AND_UPDATE_BILLING_ERROR
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case CREATE_ORDER_AND_UPDATE_BILLING:
            return {
                ...state,
                payfastForm: action.payload
            }
        case CREATE_ORDER_AND_UPDATE_BILLING_ERROR:
            return {
                ...state,
                payfastForm: null
            }
        default:
            return state
    }
}