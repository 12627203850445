import {
    PASSWORD_RESET,
    PASSWORD_RESET_ERROR
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case PASSWORD_RESET:
            return {
                success: action.payload
            }
        case PASSWORD_RESET_ERROR:
            return {
                error: action.payload.message
            }
        default:
            return state
    }
}