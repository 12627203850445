//api
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const FETCH_BRANDS = 'FETCH_BRANDS'
export const FETCH_BRAND = 'FETCH_BRAND'
export const FETCH_CATEGORIES_WITH_ONE_PRODUCT = 'FETCH_CATEGORIES_WITH_ONE_PRODUCT'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const FETCH_BRAND_PRODUCTS = 'FETCH_BRAND_PRODUCTS'
export const FETCH_BRAND_CATEGORIES = 'FETCH_BRAND_CATEGORIES'
export const FETCH_BRAND_CATEGORY_PRODUCTS = 'FETCH_BRAND_CATEGORIES'

//shopping cart
export const ADD_ITEM = 'ADD_ITEM'
export const ITEM_QTY_INCREMENT = 'ITEM_QTY_INCREMENT'
export const ITEM_QTY_DECREMENT = 'ITEM_QTY_DECREMENT'
export const ADD_BUNDLE = 'ADD_BUNDLE'
export const BUNDLE_QTY_INCREMENT = 'BUNDLE_QTY_INCREMENT'
export const BUNDLE_QTY_DECREMENT = 'BUNDLE_QTY_DECREMENT'
export const CLEAR_CART = 'CLEAR_CART'

//place order
export const CREATE_ORDER_AND_UPDATE_BILLING = 'CREATE_ORDER_AND_UPDATE_BILLING'
export const CREATE_ORDER_AND_UPDATE_BILLING_ERROR = 'CREATE_ORDER_AND_UPDATE_BILLING_ERROR'

//collection
export const FETCH_COLLECTION_QTY = 'FETCH_COLLECTION_QTY'
export const ADD_COLLECTION_ITEM = 'ADD_COLLECTION_ITEM'
export const REMOVE_COLLECTION_ITEM = 'REMOVE_COLLECTION_ITEM'
export const RESET_COLLECTION = 'RESET_COLLECTION'

//Auth
export const SIGNIN = 'SIGNIN'
export const SIGNOUT = 'SIGNOUT'
export const REGISTER = 'REGISTER'
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const PASSWORD_FORGOT = 'PASSWORD_FORGOT'
export const PASSWORD_FORGOT_ERROR = 'PASSWORD_FORGOT_ERROR'
export const PASSWORD_FIND = 'PASSWORD_FIND'
export const PASSWORD_FIND_ERROR = 'PASSWORD_FIND_ERROR'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'

//sections
export const HOMEPAGE = 'HOMEPAGE'
export const FETCH_SECTION = 'FETCH_SECTION'
export const PROMOTIONS = 'PROMOTIONS'
export const PROMOTION = 'PROMOTION'

//brochures
export const FETCH_BROCHURES = 'FETCH_BROCHURES'

//locations
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'

//blogs
export const FETCH_BLOGS = 'FETCH_BLOGS'
export const FETCH_BLOG = 'FETCH_BLOG'

//errors
export const SIGNIN_ERROR = 'SIGNIN_ERROR'
export const SIGNUP_ERROR  = 'SIGNUP_ERROR'

//contact form
export const CONTACT_FORM_MESSAGE = 'CONTACT_FORM_MESSAGE'
export const ENQUIRY = 'ENQUIRY'

//bundles
export const FETCH_BUNDLES = 'FETCH_BUNDLES'
export const FETCH_BUNDLE = 'FETCH_BUNDLE'

//subscription
export const SUBSCRIPTION = 'SUBSCRIPTION'