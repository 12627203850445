import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import BannerText from './BannerText'

class DoubleGridSection extends React.Component {
    render() {
        return(
            <Section className={`bc_double-grid-section ${this.props.order > 0 ? 'bc_swopped' : ''} ${this.props.className ? this.props.className : ''}`}>
                <Container>
                    <Row>
                        <Grid col="6" className="bc_scale">
                            <figure>
                                <img className="bc_image" src={this.props.img} alt="" className="bc_image" />
                            </figure>
                        </Grid>
                        <Grid col="6">
                            <BannerText props={this.props} />
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}
export default DoubleGridSection