import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { connect } from 'react-redux'
import SmallHeading from '../../components/Content/SmallHeading'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import Button from '../../components/Buttons/Button'
import { Redirect, Link } from 'react-router-dom'

class PasswordSuccess extends React.Component {

    renderPageContent(){
        return (
            <OnScrollAnimation>
                <SmallHeading heading="Password Reset Successful" />
                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <Row>
                            <Grid>
                                <h2>
                                    You have successfully updated your password.
                                </h2>
                            </Grid>                               
                            
                            <Grid col="12" className="bc_textAlign-center">
                                <Link to="/signin" className="bc_form-submit">Login</Link>
                            </Grid>      
                        </Row>
                    </Container>
                </Section>
                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <Row>                        
                            <Grid className="bc_textAlign-center">
                                <h2>New Customer?</h2>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                                <Button text="Signup" url="/register" />
                            </Grid>                            
                        </Row>
                    </Container>
                </Section>
            </OnScrollAnimation>
        )
    }

    render(){
        if(!this.props.resetState.success){
            return <Redirect to="/signin" />
        }
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Password Reset Success</title>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href={window.location} />
                </Helmet>             

                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        resetState: state.passwordResetState
    }
}

export default connect(mapStateToProps)(PasswordSuccess)