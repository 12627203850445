import _ from 'lodash'
import {
    PROMOTIONS,
    PROMOTION
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case PROMOTIONS:
            return {
                ...state, ..._.mapKeys(action.payload, 'search_name')
            }
        case PROMOTION:
            return {
                ...state, [action.payload.search_name]: action.payload
            }
        default:
            return state
    }
}