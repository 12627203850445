import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { 
    fetchSection
} from '../../actions'
import Nav from '../../components/Nav/Nav'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import TopBanner from '../../components/Banner/TopBanner'
import DoubleGridSection from '../../components/Content/DoubleGridSection'
import BrandCarousel from '../Brands/BrandCarousel'
import ProductCarousel from '../Products/ProductCarousel'
import ProductCategoryCarousel from '../Products/ProductCategoryCarousel'
import StoreLocations from '../Store/StoreLocations'
import { BASE_URL } from '../../paths'
import heroImg from '../../assets/img/hero-banner.png'
import BlogsCarousel from '../Blogs/BlogsCarousel'

import LazyLoad from 'react-lazyload';

class Home extends React.Component {

    componentDidMount(){

        if(!this.props.sectionState['home_top_banner']){
            this.props.fetchSection('home_top_banner')
        }
        if(!this.props.sectionState['home_section_1']){
            this.props.fetchSection('home_section_1')
        }
        if(!this.props.sectionState['home_section_2']){
            this.props.fetchSection('home_section_2')
        }
    }

    renderPageContent(){
        return(
            <React.Fragment>
                {
                    this.props.sectionState['home_top_banner'] && (
                        <OnScrollAnimation>
                            <TopBanner 
                                img={heroImg}
                                heading={this.props.sectionState['home_top_banner'].description}
                                subHeading={this.props.sectionState['home_top_banner'].title} 
                                // buttonText={this.props.sectionState['home_top_banner'].button_text}
                                // buttonLink={this.props.sectionState['home_top_banner'].link}
                            />
                        </OnScrollAnimation>
                    )
                }

                <BrandCarousel title="Brands" link="/brands" />

                <LazyLoad>
                    <OnScrollAnimation>
                        <ProductCategoryCarousel title="Products" link="/products" />
                    </OnScrollAnimation>
                </LazyLoad>
                
                
                <LazyLoad>
                    {
                        this.props.sectionState['home_section_1'] &&
                        <OnScrollAnimation>
                            <DoubleGridSection
                                img={`${BASE_URL}${this.props.sectionState['home_section_1'].image}`}
                                heading={this.props.sectionState['home_section_1'].description} 
                                subHeading={this.props.sectionState['home_section_1'].title} 
                                buttonText={this.props.sectionState['home_section_1'].button_text}
                                buttonLink={this.props.sectionState['home_section_1'].link}
                                order="0"
                                className="bc_section-home-blog"
                            />
                        </OnScrollAnimation>
                    }
                </LazyLoad>
                

                <LazyLoad>
                    <OnScrollAnimation>
                        <BlogsCarousel
                        title="Featured - Designs" />
                    </OnScrollAnimation>
                </LazyLoad>
                
                <LazyLoad>
                    <OnScrollAnimation>
                        <ProductCarousel 
                            searchName="baths" 
                            title="Featured - Baths"
                            link="/products/baths"
                        />
                    </OnScrollAnimation>
                </LazyLoad>

                <LazyLoad>
                    {
                        this.props.sectionState['home_section_2'] &&
                        <OnScrollAnimation>
                            <DoubleGridSection
                                img={`${BASE_URL}${this.props.sectionState['home_section_2'].image}`}
                                heading={this.props.sectionState['home_section_2'].description} 
                                subHeading={this.props.sectionState['home_section_2'].title} 
                                buttonText={this.props.sectionState['home_section_2'].button_text}
                                buttonLink={this.props.sectionState['home_section_2'].link}
                                order="1"
                                className="bc_section-home-blog"
                            />
                        </OnScrollAnimation>
                    }
                </LazyLoad>
                
                <LazyLoad>
                    <OnScrollAnimation>
                        <StoreLocations />
                    </OnScrollAnimation>
                </LazyLoad>
                
            </React.Fragment>
        )
    }

    render() {

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Bathroom Collective",
            "alternateName": "Bathroom Collective",
            "url": "https://bathroomcollective.co.za/",
            "logo": "https://bathroomcollective.co.za/facebook-share.jpg",
            "sameAs": [
              "https://www.facebook.com/Bathroom-Collective-100911267955966/",
              "https://www.instagram.com/bathroom_collective/",
              "http://www.pinterest.com/bathroom_collective"
            ]
          }
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Bathroom Collective | Home</title>
                    <meta name="description" content="The Bathroom Collective provides beautifully sourced luxury bathroom products to make your dream bathroom a reality." />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        sectionState: state.sectionsState
    }
}

export default connect(mapStateToProps, {
    fetchSection
})(Home)