import { 
    ENQUIRY
} from '../actions/types'

const INITIAL_STATE = {
    sent: false
}

export default (state = INITIAL_STATE, action) => {    
    switch (action.type){
        case ENQUIRY:
            return {
                ...state,
                sent: true
            }
        
        default:
            return state
    }
}