import React from 'react'
import {
    Row,
    Grid
} from '../Grid'
import { Link } from 'react-router-dom'

class SectionHeading extends React.Component {
    render() {
        if(this.props.link){
            return (
                <Row>
                    <Grid >
                        <Link to={this.props.link}>
                            <h2>{this.props.text}</h2>
                        </Link>                        
                    </Grid>
                </Row>
            )
        }
        return(
            <Row>
                <Grid >
                    <h2>{this.props.text}</h2>
                </Grid>
            </Row>
        )
    }
}
export default SectionHeading