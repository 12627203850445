import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import Footer from '../Footer/Footer'
import logo from '../../assets/img/bathroom-collective-logo.svg'
import logoMobile from '../../assets/img/bathrrom-collective-logo-favicon.png'
import menuIcon from '../../assets/img/Hamburger-Icon.svg'
import wishlistIcon from '../../assets/img/my-collection-icon.svg'
import profileIcon from '../../assets/img/profile-icon.svg'
import basketIcon from '../../assets/img/cart-icon.svg'
import pattern from '../../assets/img/pattern.svg'
import ScrollToTopArrow from '../ui/ScrollToTopArrow'
import { connect } from 'react-redux'
import { 
    fetchCollectionTotal
} from '../../actions'

class Nav extends React.Component {

    state = {
        sidebar: '',
        headerTransformation: false,
        openAuthMenu: false
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleHeaderTransformation)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleHeaderTransformation)
    }

    handleHeaderTransformation = () => {
        if(window.scrollY > 1){
            if(!this.state.headerTransformation){
                this.setState({
                    headerTransformation: true
                })
            }
            
        } else {
            if(this.state.headerTransformation){
                this.setState({
                    headerTransformation: false
                })
            }
        }
    }

    onMenuOpen = () => {
        this.setState({
            sidebar: 'open'
        })
    }

    onMenuClosed = () => {
        this.setState({
            sidebar: ''
        })
    }

    onAuthMenuOpen = () => {
        this.setState({
            openAuthMenu: !this.state.openAuthMenu
        })
    }

    onAuthMenuClose = () => {
        this.setState({
            openAuthMenu: false
        })
    }

    render() {
        return(
            
            <div className={`main ${this.state.sidebar}`}> 

                <Sidebar close={this.onMenuClosed} open={this.onMenuOpen} />
                
                <div className="page_content" style={{ backgroundImage: `url(${pattern})` }}>
                    <header className={this.state.headerTransformation ? 'bc_transformed' : ''}>
                        <div className="bc_logo">
                            <Link to='/'>
                                <img src={logo} alt="Bathroom Collective Logo" className="bc_widescreen" />
                            </Link>
                        </div>
                        <div className="bc_header-top-left-column">
                            <button className="bc_btn-menu" onClick={this.onMenuOpen}>
                                <img src={menuIcon} alt="Menu" />
                                <span>EXPLORE</span>
                            </button>
                        </div>
                        

                        <div className="bc_header-top-right-column">
                            {/* <button className="bc_btn-profile" onClick={this.onAuthMenuOpen}>
                                {this.props.isSignedIn && 
                                    <Link to="/profile">
                                        <img src={profileIcon} alt="" />
                                    </Link>
                                }

                                {!this.props.isSignedIn && 
                                    <Link to="/signin">
                                        <img src={profileIcon} alt="" />
                                    </Link>
                                    
                                }
                                
                            </button> */}
                            {/* <button className="bc_btn-basket">
                                <Link to="/cart">
                                    <img src={basketIcon} alt="" />
                                    <span>
                                        {this.props.cart.totalQuantity}
                                    </span>
                                </Link>
                            </button> */}

                            <button className="bc_btn-wishlist">
                                <Link to="/collections">
                                    <img src={wishlistIcon} alt="" />
                                    <span>{this.props.collectionCount}</span>
                                </Link>
                            </button>
                        </div>

                        <ScrollToTopArrow />
                        
                    </header>
                    
                    <div className="page_border">
                        {this.props.children}
                    </div>

                    <Footer />

                </div>
            </div>
        )
    }
}

const MapStateToProps = state => ({
    cart: state.cartState,
    isSignedIn: state.authState.isSignedIn,
    collectionCount: state.collectionState.totalQuantity
})

export default connect(MapStateToProps)(Nav)