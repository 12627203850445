import { 
    SIGNIN,
    SIGNIN_ERROR,
    SIGNOUT,
    REGISTER,
    FETCH_USER_DETAILS
} from '../actions/types'

export default (state = {}, action) => {    
    switch (action.type){
        
        case FETCH_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload.success
            }
        case REGISTER:
            return { ...state, 
                isSignedIn: true, 
                userId: action.payload.userId,
                userToken: action.payload.success.token,
                errors: null }

        case SIGNIN:       
            return { ...state, 
                isSignedIn: true, 
                userId: action.payload.userId,
                userToken: action.payload.success.token,
                errors: null }

        case SIGNOUT:
            return {...state, 
                isSignedIn: null, 
                userId: null,
                userToken: null,
                errors: null,
                userDetails: null
            } 

        case SIGNIN_ERROR:
            return {...state,
                isSignedIn: null, 
                userId: null,
                userToken: null,
                errors: true
            } 
            
        default:
            return state
    }
}