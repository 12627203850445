import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import SmallHeading from '../../components/Content/SmallHeading'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Button from '../../components/Buttons/Button'
import {
    passwordForgot
} from '../../actions'
import Loader from '../../components/ui/Loader'

class PasswordForgot extends React.Component {

    state = {
        loader: false
    }

    componentWillUnmount(){
        this.setState({
            loader: false
        })
    }

    onSubmit = formValues => {        

        this.setState({
            loader: true
        })

        this.props.passwordForgot(formValues)

        setTimeout(() => {
            this.setState({
                loader: false
            })
        }, 3000);
    }

    renderForm = () => { 
        if(this.props.forgot.success){
            return (
                <>
                    <Grid>
                        <h2>
                            {this.props.forgot.success}
                        </h2>
                    </Grid>
                </>
            )
        } else if (this.props.forgot.error){
            return (
                <>
                    <Grid>
                        <h2>
                            We were unable to find your email address.
                        </h2>
                    </Grid>
                    <Grid col="12">
                        <Input name="email" type="email" label="Email" required />
                    </Grid>
                    
                    <Grid col="12" className="bc_textAlign-center">
                        <input className="bc_form-submit" type="submit" value="Submit" />
                    </Grid>
                </>
            )
        }
        return (
            <>
                <Grid>
                    <h2>
                        Enter Email Address
                    </h2>
                </Grid>
                <Grid col="12">
                    <Input name="email" type="email" label="Email" required />
                </Grid>
                
                <Grid col="12" className="bc_textAlign-center">
                    <input className="bc_form-submit" type="submit" value="Submit" />
                </Grid>
            </>
        )
    }

    renderPageContent(){
        return (
            <OnScrollAnimation>
                <SmallHeading heading="Password Forgot" />
                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <Row>
                                {this.renderForm()}            
                            </Row>
                        </form>
                    </Container>
                </Section>
                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <Row>
                        
                            <Grid className="bc_textAlign-center">
                                <h2>New Customer?</h2>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                                <Button text="Signup" url="/register" />
                            </Grid>                            
                        </Row>
                    </Container>
                </Section>
            </OnScrollAnimation>
        )
    }

    render(){
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Password Forgot</title>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                { this.state.loader && <Loader /> }               

                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        forgot: state.passwordForgotState
    }
}

const formWrapped = reduxForm({
    form: 'ForgotPassword'
})(PasswordForgot)

export default connect(mapStateToProps, {
    passwordForgot
})(formWrapped)