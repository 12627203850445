import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
//persisting the state after page refresh by saving it into local storage
import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

//reducers
import productsReducer from './productsReducer'
import productReducer from './productReducer'
import cartReducer from './cartReducer'
import authReducer from './authReducer'
import brandsReducer from './brandsReducer'
import categoriesReducer from './categoriesReducer'
import categoryReducer from './categoryReducer'
import collectionReducer from './collectionReducer'
import homepageReducer from './homepageReducer'
import promotionReducer from './promotionReducer'
import brochuresReducer from './brochuresReducer'
import sectionsReducer from './sectionsReducer'
import blogsReducer from './blogsReducer'
import orderReducer from './orderReducer'
import locationsReducer from './locationsReducer'
import brandReducer from './brandReducer'
import makeAnAppointmentReducer from './makeAnAppointmentReducer'
import brandCategoryReducer from './brandCategoryReducer'
import profileReducer from './profileReducer'
import registerReducer from './registerReducer'
import passwordForgotReducer from './passwordForgotReducer'
import passwordFindReducer from './passwordFindReducer'
import passwordResetReducer from './passwordResetReducer'
import bundlesReducer from './bundlesReducer'
import bundleReducer from './bundleReducer'
import enquireReducer from './enquireReducer'
import subscriptionReducer from './subscriptionReducer'

const sessionPersistConfig = {
    key: 'root',
    storage: sessionStorage,
    blacklist: [
        'collectionState',
    ],
    whitelist: [
        'productsState',
        'productState',
        'cartState', 
        'collectionState', 
        'productsState', 
        'orderState', 
        'authState',
        'brandsState',
        'brandState',
        'categoriesState',
        'categoryState',
        'collectionState',
        'homepageState',
        'promotionState',
        'brochuresState',
        'sectionsState',
        'blogsState',
        'orderState',
        'locationsState',
        'brandCategoryState',
        'bundlesState',
        'bundleState',
        'enquireState',
        'subscriptionState'
    ],
    stateReconciler: autoMergeLevel2
}

const localStoragePersistConfig = {
    key: 'root',
    storage,    
}

const rootReducer = combineReducers({
    productsState: productsReducer,
    productState: productReducer,
    cartState: cartReducer,
    authState: authReducer,
    brandsState: brandsReducer,
    brandState: brandReducer,
    categoriesState: categoriesReducer,
    categoryState: categoryReducer,
    collectionState: persistReducer(localStoragePersistConfig, collectionReducer),
    homepageState: homepageReducer,
    promotionState: promotionReducer,
    brochuresState: brochuresReducer,
    sectionsState: sectionsReducer,
    blogsState: blogsReducer,
    orderState: orderReducer,
    locationsState: locationsReducer,
    makeAnAppointmentState: makeAnAppointmentReducer,
    brandCategoryState: brandCategoryReducer,
    profileState: profileReducer,
    registerState: registerReducer,
    passwordForgotState: passwordForgotReducer,
    passwordFindState: passwordFindReducer,
    passwordResetState: passwordResetReducer,
    bundlesState: bundlesReducer,
    bundleState: bundleReducer,
    enquireState: enquireReducer,
    subscriptionState: subscriptionReducer,
    form: formReducer,
})

export default persistReducer(sessionPersistConfig, rootReducer)