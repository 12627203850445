import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { 
    fetchCategory,
    fetchCategoriesWithOneProduct
} from '../../actions'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import TopBanner from '../../components/Banner/TopBanner'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import heroImg from '../../assets/img/hero.png'
import { BASE_URL } from '../../paths'
import Filter from '../../components/Filters/Filter'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'

class Products extends React.Component {

    componentDidMount(){
        this.props.fetchCategory(this.props.match.params.searchName)
        this.props.fetchCategoriesWithOneProduct()
    }

    componentDidUpdate(){
        if(!this.props.data){
            this.props.fetchCategory(this.props.match.params.searchName)
        }
        if(!this.props.category){
            this.props.fetchCategoriesWithOneProduct()
        }        
    }

    renderList(){
        return this.props.data.map((product, index) => {
            if(product.brand){
                return(
                    <Grid col="4" key={index} className="bc_item">
                        <Link to={`/${product.brand.search_name}/${product.category.search_name}`}>
                            <ImageScaleOnHover>
                                <figure>
                                    <img src={`${BASE_URL}${product.image}`} alt="" className="bc_image" />
                                </figure>
                            </ImageScaleOnHover>
                            
                            <h4>
                                <div><span style={{textTransform: 'upperCase'}}>{product.brand.title}</span> {product.title}</div>
                            </h4>
                        </Link>
                    </Grid>
                )
            }
        })
    }

    renderPageContent() {
        return(
            <React.Fragment>

                <TopBanner 
                    img={heroImg}
                    heading={this.props.category ? this.props.category.description : ''} 
                    subHeading={this.props.category ? `Featured - ${this.props.category.title}` : ''} 
                />

                <OnScrollAnimation>
                    <Section className="bc_product-item-section">
                        <Filter />
                        <Container>
                            <Row>
                                {this.props.data && this.renderList()}
                            </Row>
                        </Container>                     
                    </Section>
                </OnScrollAnimation>

            </React.Fragment>
        )
    }
    
    renderMetaDescription = () => {
        return this.props.category.meta_description ? this.props.category.meta_description : this.props.category.description
    }

    render() {

        const ldJson = {
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Home",
              "item": "https://bathroomcollective.co.za"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Products",
              "item": "https://bathroomcollective.co.za/products"  
            },{
              "@type": "ListItem", 
              "position": 3, 
              "name": this.props.category ? this.props.category.title : '',
              "item": window.location.href
            }]
        }
        
        return(
            <React.Fragment>

                <Helmet>
                    <title>{this.props.category ? this.props.category.title + ' - ' : ''} Bathroom Collective</title>
                    {
                        this.props.category && (
                            <meta name="description" content={this.renderMetaDescription()} />
                        )
                    }
                    
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                <Nav>{this.renderPageContent()}</Nav>        

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.categoryState[ownProps.match.params.searchName],
        category: state.categoriesState[ownProps.match.params.searchName]
    }
}

export default connect(mapStateToProps, { 
    fetchCategory,
    fetchCategoriesWithOneProduct
})(Products)