import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { connect } from 'react-redux'
import SmallHeading from '../../components/Content/SmallHeading'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'
import {
    fetchUserDetails,
    updateProfile
} from '../../actions'
import AuthForm from './AuthForm'
import Loader from '../../components/ui/Loader'
import Popup from '../../components/Popups/Popup'
import Button from '../../components/Buttons/Button'
import closeImg from '../../assets/img/iconmonstr-x-mark-9-240.png'

class Profile extends React.Component {

    state = {
        loader: false,
        popup: false
    }

    componentDidMount(){
        this.props.fetchUserDetails(this.props.auth.userId, this.props.auth.userToken)
    }

    componentWillUnmount(){
        this.setState({
            loader: false,
            popup: false
        })
    }

    closePopup = () => {
        this.setState({
            popup:false,
        })
    }

    onSubmit = formValues => {
        this.setState({
            loader: true
        })

        setTimeout(() => {
            if(this.props.updateProfile(this.props.auth.userToken, formValues)){
                this.setState({
                    loader: false,
                    popup: true
                })
            }
        }, 1000);
        window.scrollTo(0, 0)
    }

    renderPageContent(){
        return (
            <OnScrollAnimation>
                
                <SmallHeading heading="Profile" />
                
                <AuthForm 
                    heading="Account Information" 
                    onSubmit={this.onSubmit} 
                    initialValues={this.props.auth.userDetails}
                    
                />

            </OnScrollAnimation>
        )
    }

    render() {

        if(!this.props.auth.userDetails){
            return <></>
        }

        if(this.state.popup && this.props.update.success){
            return (
                <Popup>
                    <button className="bc_popup-close-btn" onClick={this.closePopup}>
                        <img src={closeImg} alt="" />
                    </button>
                    <h2>Profile updated Successfully!</h2>
                    <div className="bc_popup-flex">
                        <Button
                            text="Explore"
                            url="/"
                        />
                    </div>
                </Popup>
            )
        }

        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Profile</title>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                { this.state.loader && <Loader /> }

                <Nav>{this.renderPageContent()}</Nav>
                
            </React.Fragment>
        )
    }
}



const mapStateToProps = state => {
    return {
        auth: state.authState,
        update: state.profileState
    }
}

export default connect(mapStateToProps, {
    fetchUserDetails,
    updateProfile
})(Profile)