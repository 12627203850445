import React from 'react'
import { connect } from 'react-redux'
import { fetchBrochures } from '../../actions'
import Carousel from '../../components/SlickSlider/Carousel'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import SectionHeading from '../../components/Content/SectionHeading.js'
import { BASE_URL } from '../../paths'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'

import LazyLoad from 'react-lazyload';

class BrochureCarousel extends React.Component {

    componentDidMount(){
        this.props.fetchBrochures(this.props.brandId)
    }

    renderList(){
        return this.props.data.map((item, index) => {
            return (
                <div className="bc_item" key={index}>
                    <a href={`${BASE_URL}${item.pdf}`} target="_blank" rel="noopener noreferrer" download>
                        <ImageScaleOnHover>
                            {
                                index > 3 && (
                                    <LazyLoad>
                                        <figure>
                                            <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" style={{minHeight: '100px'}} />
                                        </figure>
                                    </LazyLoad>
                                )
                            }
                            {
                                index <= 3 && (
                                    <figure>
                                        <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" style={{minHeight: '100px'}} />
                                    </figure>
                                )
                            }
                            
                            
                        </ImageScaleOnHover>
                        
                        <div className="bc_bottom-title">
                            <h4>
                                <span style={{textTransform: 'upperCase'}}>{item.title}</span>
                            </h4>
                        </div>
                    </a>                    
                </div>
            )
        })
    }

    renderItems(){
        return this.props.data.map((item, index) => {
            return (
                <Grid col="4" key={index}>
                    <div className="bc_item">
                        <a href={`${BASE_URL}${item.pdf}`} target="_blank" rel="noopener noreferrer" download>
                            <ImageScaleOnHover>
                                <figure>
                                    <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" />
                                </figure>
                            </ImageScaleOnHover>
                            
                            <div className="bc_bottom-title">
                                <h4>
                                    <span style={{textTransform: 'upperCase', color: 'white'}}>{item.title}</span>
                                </h4>
                            </div>
                        </a>                    
                    </div>
                </Grid>
            )
        })
    }

    renderContent(){
        if(this.props.data.length > 3){
            return(
                <Carousel>
                    {this.renderList()}
                </Carousel>
            )
        } else {
            return(
                <Row>
                    {this.renderItems()}
                </Row>
            )
        }
        
    }

    render(){
        if(!this.props.data || this.props.data.length === 0){return <></>}

        return(
            <Section className="bc_slider-section bc_less-height">
                <Container>
                    <SectionHeading text="Brochures" />
                    {this.renderContent()}
                </Container>
            </Section>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        data: Object.values(state.brochuresState)
    }
}

export default connect(mapStateToProps, {
    fetchBrochures
})(BrochureCarousel)