import {
    ADD_ITEM,
    ITEM_QTY_INCREMENT,
    ITEM_QTY_DECREMENT,
    ADD_BUNDLE,
    BUNDLE_QTY_INCREMENT,
    BUNDLE_QTY_DECREMENT,
    CLEAR_CART
} from '../actions/types'

//initial state object before any items has been added to the cart
const intialState = {
    totalCost: 0,
    totalQuantity: 0,
    items: {},
    bundles: {}
}

export default (state = intialState, action) => {

    let quantity = 0;

    switch(action.type){

        case ADD_ITEM:

            //checks if item exists in cart state
            if(state.items[action.payload.id]){
                let item = { ...state.items[action.payload.id] }
                quantity = item.quantity
            }

            //returns a new state object with the added item / updated quantity of existing item in the cart
            return{
                ...state,
                totalCost: state.totalCost + parseInt(action.payload.price),
                totalQuantity: state.totalQuantity + 1,
                items: {
                    ...state.items,
                    [action.payload.id]: {
                        key: action.payload.id,
                        title: action.payload.title,
                        description: action.payload.description,
                        price: parseInt(action.payload.price),
                        quantity: quantity + 1,
                        image: action.payload.image
                    }
                }
            }  

        case ADD_BUNDLE:

            //checks if item exists in cart state
            if(state.bundles[action.payload.id]){
                let bundle = { ...state.bundles[action.payload.id] }
                quantity = bundle.quantity
            }

            //returns a new state object with the added item / updated quantity of existing item in the cart
            return{
                ...state,
                totalCost: state.totalCost + parseInt(action.payload.special_price),
                totalQuantity: state.totalQuantity + 1,
                bundles: {
                    ...state.bundles,
                    [action.payload.id]: {
                        key: action.payload.id,
                        title: action.payload.title,
                        description: action.payload.description,
                        price: parseInt(action.payload.special_price),
                        quantity: quantity + 1,
                        image: action.payload.image
                    }
                }
            }  

        case ITEM_QTY_INCREMENT:

            return {
                ...state,
                totalCost: state.totalCost + (parseInt(state.items[action.payload.id].price) * action.payload.diff),
                totalQuantity: state.totalQuantity + action.payload.diff,
                items: {
                    ...state.items,
                    [action.payload.id]: {
                        key: action.payload.id,
                        title: state.items[action.payload.id].title,
                        description: state.items[action.payload.id].description,
                        price: parseInt(state.items[action.payload.id].price),
                        quantity: state.items[action.payload.id].quantity + action.payload.diff,
                        image: state.items[action.payload.id].image
                    }
                }
            }

        case ITEM_QTY_DECREMENT:

            return {
                ...state,
                totalCost: state.totalCost - (parseInt(state.items[action.payload.id].price) * action.payload.diff),
                totalQuantity: state.totalQuantity - action.payload.diff,
                items: {
                    ...state.items,
                    [action.payload.id]: {
                        key: action.payload.id,
                        title: state.items[action.payload.id].title,
                        description: state.items[action.payload.id].description,
                        price: parseInt(state.items[action.payload.id].price),
                        quantity: state.items[action.payload.id].quantity - action.payload.diff,
                        image: state.items[action.payload.id].image
                    }
                }
            }

        case BUNDLE_QTY_INCREMENT:

            return {
                ...state,
                totalCost: state.totalCost + (parseInt(state.bundles[action.payload.id].price) * action.payload.diff),
                totalQuantity: state.totalQuantity + action.payload.diff,
                bundles: {
                    ...state.bundles,
                    [action.payload.id]: {
                        key: action.payload.id,
                        title: state.bundles[action.payload.id].title,
                        description: state.bundles[action.payload.id].description,
                        price: parseInt(state.bundles[action.payload.id].price),
                        quantity: state.bundles[action.payload.id].quantity + action.payload.diff,
                        image: state.bundles[action.payload.id].image
                    }
                }
            }

        case BUNDLE_QTY_DECREMENT:

            return {
                ...state,
                totalCost: state.totalCost - (parseInt(state.bundles[action.payload.id].price) * action.payload.diff),
                totalQuantity: state.totalQuantity - action.payload.diff,
                bundles: {
                    ...state.bundles,
                    [action.payload.id]: {
                        key: action.payload.id,
                        title: state.bundles[action.payload.id].title,
                        description: state.bundles[action.payload.id].description,
                        price: parseInt(state.bundles[action.payload.id].price),
                        quantity: state.bundles[action.payload.id].quantity - action.payload.diff,
                        image: state.bundles[action.payload.id].image
                    }
                }
            }

        case CLEAR_CART:
            return {
                totalCost: 0,
                totalQuantity: 0,
                items: {},
                bundles: {}
            }

        default:
            return state
    }
}