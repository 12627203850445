import {
    SUBSCRIPTION
} from '../actions/types'

const initialState = {
    subcribed: false
}

export default (state = initialState, action) => {
    switch(action.type){
        case SUBSCRIPTION:
            return {
                ...state, 
                subscribed: true
            }
        default:
            return state
    }
}