import React from 'react'
import { connect } from 'react-redux'
import closeIcon from '../../assets/img/close-icon.svg'
import facebook from '../../assets/img/facebook-logo-button.svg'
import instagram from '../../assets/img/instagram.svg'
import pinterest from '../../assets/img/pinterest-logo-button.svg'
import NavLink from './NavLink'
import SocialNavLink from './SocialNavLink'
import menu_image from '../../assets/img/menu-image@2x.jpg'

class Sidebar extends React.Component {

    render() {
        const close = this.props.close
        return(
            <div className="bc_sidebar">
                    
                <button className="bc_btn-menu" onClick={close}>
                    <img src={closeIcon} alt="close" width="30" height="30" />
                </button>

                <ul className="bc_ul_big">
                    
                    <NavLink href="/" text="Home" />
                    <NavLink href="/brands" text="Brands" />
                    <NavLink href="/products" text="Products" />                  
                    {/* <NavLink href="/cart" text="Cart" /> */}
                    <NavLink href="/collections" text="My Collection" />
                    <li className="bc_list-item_big">
                        <a href="https://www.cpandb.co.za/news" target="_blank" rel="noreferrer">Design Inspiration</a>
                    </li>
                    <NavLink href="/contact" text="Contact Us" />
                    {this.props.isSignedIn && <NavLink href="/profile" text="Profile" />} 
                    {!this.props.isSignedIn && <NavLink href="/signin" text="Sign In" />} 
                    
                    <li className="bc_list-item_flex" style={{marginTop: '50px'}}>
                        <SocialNavLink href="https://www.facebook.com/Bathroom-Collective-100911267955966/" src={facebook} alt="facebook" />
                        <SocialNavLink href="https://www.instagram.com/bathroom_collective/" src={instagram}  alt="instagram" />
                        <SocialNavLink href="http://www.pinterest.com/bathroom_collective" src={pinterest} alt="pinterest" />
                    </li>
                    <li className="bc_list-item_flex">
                    <p style={{fontSize: '12px'}}><strong>Bathroom Collective</strong> | All Rights Reserved | Powered by <a href="https://optimalonline.co.za" style={{color:'white'}} target="_blank"  rel="noopener noreferrer"><strong>OPTIMAL ONLINE</strong></a></p>
                    </li>
                </ul>

                <img src={menu_image} alt="Bathroom Collective Show Room" className="bc_sidebar-image" />
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSignedIn: state.authState.isSignedIn
    }
}

export default connect(mapStateToProps)(Sidebar)