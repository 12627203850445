import products from '../api/products'
import {
    FETCH_PRODUCTS,
    FETCH_PRODUCT,
    ADD_ITEM,
    ITEM_QTY_INCREMENT,
    ITEM_QTY_DECREMENT,
    ADD_BUNDLE,
    BUNDLE_QTY_INCREMENT,
    BUNDLE_QTY_DECREMENT,
    SIGNIN,
    SIGNOUT,
    SIGNIN_ERROR,
    REGISTER,
    FETCH_USER_DETAILS,
    SIGNUP_ERROR,
    UPDATE_PROFILE,
    UPDATE_PROFILE_ERROR,
    FETCH_BRANDS,
    FETCH_CATEGORIES_WITH_ONE_PRODUCT,
    FETCH_CATEGORY,
    FETCH_BRAND,
    FETCH_COLLECTION_QTY,
    ADD_COLLECTION_ITEM,
    REMOVE_COLLECTION_ITEM,
    RESET_COLLECTION,
    PROMOTIONS,
    PROMOTION,
    FETCH_BROCHURES,
    FETCH_SECTION,
    FETCH_BLOGS,
    FETCH_BLOG,
    CREATE_ORDER_AND_UPDATE_BILLING,
    CREATE_ORDER_AND_UPDATE_BILLING_ERROR,
    FETCH_LOCATIONS,
    CONTACT_FORM_MESSAGE,
    CLEAR_CART,
    FETCH_BRAND_PRODUCTS,
    FETCH_BRAND_CATEGORIES,
    PASSWORD_FORGOT,
    PASSWORD_FORGOT_ERROR,
    PASSWORD_FIND,
    PASSWORD_FIND_ERROR,
    PASSWORD_RESET,
    PASSWORD_RESET_ERROR,
    FETCH_BUNDLES,
    FETCH_BUNDLE,
    FETCH_BRAND_CATEGORY_PRODUCTS,
    ENQUIRY,
    SUBSCRIPTION
} from './types'
import history from '../history'

//BLOGS
export const fetchBlogs = () => async dispatch => {
    const response = await products.get('blogs')
    dispatch({
        type: FETCH_BLOGS,
        payload: response.data
    })
}

export const fetchBlog = searchName => async dispatch => {
    const response = await products.get(`blogs/${searchName}`)
    dispatch({
        type: FETCH_BLOG,
        payload: response.data
    })
}

//SECTIONS

export const fetchSection = searchName => async dispatch => {
    const response = await products.get(`sections/${searchName}`)
    dispatch ({
        type: FETCH_SECTION,
        payload: response.data
    })
}

export const fetchPromotions = () => async dispatch => {
    const response = await products.get('sections/promotions')
    dispatch ({
        type: PROMOTIONS,
        payload: response.data
    })
}

export const fetchPromotion = searchName => async dispatch => {
    const response = await products.get(`sections/promotions/${searchName}`)
    dispatch ({
        type: PROMOTION,
        payload: response.data
    })
}

//BROCHURES

export const fetchBrochures = brandId => async dispatch => {
    const response = await products.get(`brochures/${brandId}`)
    dispatch({
        type: FETCH_BROCHURES,
        payload: response.data
    })
}

//AUTH

export const updateProfile = (token, formValues) => async dispatch => {

    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await products.post('/account', formValues, config)

        dispatch({
            type: UPDATE_PROFILE,
            payload: response.data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_ERROR,
            payload: error
        })
    }
}

export const updateBilling = (token, formValues) => async dispatch => {

    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const response = await products.post('/billing', formValues, config)

        dispatch({
            type: UPDATE_PROFILE,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}

export const fetchUserDetails = (id, token) => async dispatch => {

    const config = { headers: { Authorization: `Bearer ${token}` } };
    
    const bodyParameters = { id: id };

    const response = await products.post('/details', bodyParameters, config)

    dispatch ({
        type: FETCH_USER_DETAILS,
        payload: response.data
    })
}

export const signUp = formValues => async dispatch => {
    try {

        const response = await products.post('/register', formValues) 
        
        dispatch({
            type: REGISTER,
            payload: response.data
        })

    } catch (error) {
        dispatch({
            type: SIGNUP_ERROR,
            payload: error
        })
    }
}

export const signIn = formValues => async dispatch => {

    try {
        
        const response = await products.post('/login', formValues) 
        
        dispatch({
            type: SIGNIN,
            payload: response.data
        })

        history.push('/')

    } catch (error) {
        dispatch({
            type: SIGNIN_ERROR,
            payload: error
        })
    }
}

export const signOut = token => async dispatch => {

    const config = { 
        headers: { 
            Authorization: `Bearer ${token}` 
        } 
    };

    const formValues = {}
        
    const response = await products.post('/logout', formValues, config) 
    
    dispatch({
        type: SIGNOUT
    })
}

export const passwordForgot = formValues => async dispatch => {

    try {
        const response = await products.post('/password/create', formValues)
        
        dispatch({
            type: PASSWORD_FORGOT,
            payload: response.data
        })
    } catch(error){
        dispatch({
            type: PASSWORD_FORGOT_ERROR,
            payload: error
        })
    }
}

export const passwordFind = passwordResetToken => async dispatch => {

    try {
        const response = await products.post(`/password/find/${passwordResetToken}`)
        
        dispatch({
            type: PASSWORD_FIND,
            payload: response.data
        })
    } catch(error){
        dispatch({
            type: PASSWORD_FIND_ERROR,
            payload: error
        })
    }
}

export const passwordReset = (formValues, email, token) => async dispatch => {
    const data = {
        email,
        token,
        password: formValues.password,
        c_password: formValues.c_password
    }

    try {
        const response = await products.post('/password/reset', data)
        
        dispatch({
            type: PASSWORD_RESET,
            payload: response.data
        })
        history.push('/password/success')
    } catch(error){
        dispatch({
            type: PASSWORD_RESET_ERROR,
            payload: error
        })
    }
}

//PRODUCTS
export const fetchProducts = data => async dispatch => {

    const response = await products.get(`/products${data ? '?page=' + data.page : ''}`)

    dispatch({
        type: FETCH_PRODUCTS,
        payload: {
            data: response.data
        }
    })
}

export const fetchCategoryProducts = (data, category_id) => async dispatch => {

    const response = await products.get(`/products/category/${category_id}${data ? '?page=' + data.page : ''}`)

    dispatch({
        type: FETCH_PRODUCTS,
        payload: {
            category_id,
            data: response.data
        }
    })
}

export const fetchBrandProducts = searchName => async dispatch => {

    const response = await products.get(`/products/brand/${searchName}`)

    dispatch({
        type: FETCH_BRAND_PRODUCTS,
        payload: {
            searchName,
            data: response.data
        }
    })
}

export const fetchProduct = searchName => async dispatch => {
    const response = await products.get(`/products/${searchName}`)
    dispatch({
        type: FETCH_PRODUCT,
        payload: response.data
    })
}


//CATEGORIES
export const fetchCategoriesWithOneProduct = () => async dispatch => {
    const response = await products.get('/categories');
    dispatch({
        type: FETCH_CATEGORIES_WITH_ONE_PRODUCT,
        payload: response.data
    })
}

export const fetchCategory = (searchName) => async dispatch => {
    const response = await products.get(`/categories/${searchName}`)
    dispatch({
        type: FETCH_CATEGORY,
        payload: {
            searchName,
            data: response.data
        }
    })
}

export const fetchBrandCategoryProducts = (page, brandSearchName, categorySearchName) => async dispatch => {
    const response = await products.get(`/products/${brandSearchName}/${categorySearchName}${page ? '?page=' + page : ''}`)
    dispatch({
        type: FETCH_BRAND_CATEGORY_PRODUCTS,
        payload: {
            categorySearchName,
            data: response.data
        }
    })
}



//BRANDS
export const fetchBrands = () => async dispatch => {
    const response = await products.get('/brands')
    dispatch({
        type: FETCH_BRANDS,
        payload: response.data
    })
}

export const fetchBrand = searchName => async dispatch => {
    const response = await products.get(`/brands/${searchName}`)
    dispatch({
        type: FETCH_BRAND,
        payload: response.data
    })
}

export const fetchBrandCategories = brandSearchName => async dispatch => {
    
    const response = await products.get(`/brands/${brandSearchName}/categories`)
    
    dispatch({
        type: FETCH_BRAND_CATEGORIES,
        payload: {
            brandSearchName,
            data: response.data
        }
    })
}

//LOCATIONS
export const fetchLocations = () => async dispatch => {
    const response = await products.get('/locations')
    dispatch({
        type: FETCH_LOCATIONS,
        payload: response.data
    })
}

export const addItem = searchName => async dispatch => {
    const response = await products.get(`/products/${searchName}`)
    dispatch({
        type: ADD_ITEM,
        payload: response.data
    })
}

export const itemQtyIncrement = (id, diff) => {
    return (dispatch) => {
        dispatch({
            type: ITEM_QTY_INCREMENT,
            payload: {
                id: id, diff: diff
            }
        })
    }
}

export const itemQtyDecrement = (id, diff) => {
    return (dispatch) => {
        dispatch({
            type: ITEM_QTY_DECREMENT,
            payload: {
                id: id, diff: diff
            }
        })
    }
}

export const addBundle = searchName => async dispatch => {
    const response = await products.get(`/bundles/${searchName}`)
    dispatch({
        type: ADD_BUNDLE,
        payload: response.data
    })
}

export const bundleQtyIncrement = (id, diff) => {
    return (dispatch) => {
        dispatch({
            type: BUNDLE_QTY_INCREMENT,
            payload: {
                id: id, diff: diff
            }
        })
    }
}

export const bundleQtyDecrement = (id, diff) => {
    return (dispatch) => {
        dispatch({
            type: BUNDLE_QTY_DECREMENT,
            payload: {
                id: id, diff: diff
            }
        })
    }
}

export const clearCart = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CART
        })
    }
}

export const createOrderUpdateBilling = (token, cart, formValues) => async dispatch => {
    
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };

        const data = {
            cart,
            formValues
        }

        const response = await products.post('/order',  data, config)

        dispatch({
            type: CREATE_ORDER_AND_UPDATE_BILLING,
            payload: response.data
        })

    } catch (error) {
        dispatch({
            type: CREATE_ORDER_AND_UPDATE_BILLING_ERROR,
            payload: error
        })
    }
}

//COLLECTION

export const fetchCollectionTotal = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_COLLECTION_QTY
        })
    }
}

export const addCollectionItem = searchName => async dispatch => {
    const response = await products.get(`/products/${searchName}`)
    dispatch({
        type: ADD_COLLECTION_ITEM,
        payload: response.data
    })
}

export const removeCollectionItem = item_id => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_COLLECTION_ITEM,
            payload: item_id
        })
    }
}

export const resetCollection = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_COLLECTION
        })
    }
}

export const makeAnAppointmentForm = (formValues, collection) => async dispatch => {
    const data = {
        formValues,
        collection
    }

    const response = await products.post('contact', data)
    dispatch({
        type: CONTACT_FORM_MESSAGE
    })

    history.push('/collections/thankyou')
}

export const enquireNowForm = (formValues, item) => async dispatch => {
    const data = {
        formValues,
        item
    }
    
    const response = await products.post('enquire', data)

    dispatch({
        type: ENQUIRY
    })

    history.push('/enquire/thankyou')
}

export const fetchBundles = () => async dispatch => {
    const response = await products.get('/bundles')
    dispatch({
        type: FETCH_BUNDLES,
        payload: response.data
    })
}

export const fetchBundle = searchName => async dispatch => {
    const response = await products.get(`/bundles/${searchName}`)
    dispatch({
        type: FETCH_BUNDLE,
        payload: response.data
    })
}

export const subscription = formValues => async dispatch => {

    const response = await products.post('subscription', formValues)

    dispatch({
        type: SUBSCRIPTION,
        payload: response.data
    })

    history.push('/subscription/thankyou')
}