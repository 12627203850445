import React from 'react'
import {
    Route,
    Redirect
} from "react-router-dom"
import { connect } from 'react-redux'

const PrivateRoute = props => {
    if(props.isSignedIn){
        return (<Route 
            exact 
            path={props.path}
            component={props.component}
        />)
    }

    return (
        <Redirect to={{
            pathname: '/signin',
            state: { from: props.location }
        }} 
        />
    )
}

const MapStateToProps = state => ({
    isSignedIn: state.authState.isSignedIn
})

export default connect(MapStateToProps)(PrivateRoute)