import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import SmallHeading from '../../components/Content/SmallHeading'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Button from '../../components/Buttons/Button'
import {
    signIn
} from '../../actions'
import Loader from '../../components/ui/Loader'

class SignIn extends React.Component {

    state = {
        loader: false
    }

    componentWillUnmount(){
        this.setState({
            loader: false
        })
    }

    componentDidUpdate(){
        if(this.props.auth.errors && this.state.loader === true){
            this.setState({
                loader: false
            })
        }
    }

    onSubmit = formValues => {
        this.setState({
            loader: true
        })

        setTimeout(() => {
            this.props.signIn(formValues)
        }, 1000);
    }

    renderPageContent(){
        
        return(
            <OnScrollAnimation>

                <SmallHeading heading="Signin" />

                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <Row>
                            
                                <Grid>
                                    <h2>Returning Customer</h2>
                                    {
                                        this.props.auth.errors && <p className="bc_form-error-msg">Unauthorized. Please sign up or make sure you have entered the correct username and password.</p>
                                    }
                                    
                                </Grid>
                                <Grid col="6">
                                    <Input name="email" type="email" label="Email" required />
                                </Grid>
                                <Grid col="6">
                                    <Input name="password" type="password" label="Password" required />
                                </Grid>
                                <Grid col="6">
                                    <input className="bc_form-submit" type="submit" value="Login" />
                                </Grid>     
                                <Grid col="6">
                                    <div className="bc_display-flex" style={{justifyContent: "space-between"}}>
                                        
                                        <div>
                                            <Link to="/password/forgot" className="white">
                                                Forgot Password?
                                            </Link>
                                        </div>
                                        
                                    </div>
                                </Grid>                         
                            </Row>
                        </form>
                    </Container>
                </Section>
                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <Row>
                        
                            <Grid className="bc_textAlign-center">
                                <h2>New Customer?</h2>
                                <p>Create an account and start your journey with Bathroom Collective today.</p>
                                <Button text="Signup" url="/register" />
                            </Grid>                            
                        </Row>
                    </Container>
                </Section>
            </OnScrollAnimation>
        )
    }

    render() {

        if(this.props.auth.isSignedIn){
            return <Redirect to="/" />
        }
        
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Signin</title>
                    <meta name="description" content="Sign in or create an account and start your journey with Bathroom Collective." />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                { this.state.loader && <Loader /> }               

                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.authState
    }
}

const formWrapped = reduxForm({
    form: 'SignIn'
})(SignIn)

export default connect(mapStateToProps, {
    signIn
})(formWrapped)