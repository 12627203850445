import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import SmallHeading from '../../components/Content/SmallHeading'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'

class SubscriptionThankyou extends React.Component {

    renderPageContent(){
        return(
            <React.Fragment>
                <SmallHeading heading="Subscription" />
                <Section>
                    <Container>
                        <Row>
                            <Grid>
                                <h2 style={{color:'white', textAlign:'center'}}>Thank you for subscribing to our newsletter.</h2>
                            </Grid>
                        </Row>
                    </Container>
                </Section>
            </React.Fragment>
        )
    }
    render() {
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Thank You</title>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                
                <Nav>{this.renderPageContent()}</Nav>
                
            </React.Fragment>
        )
    }
}

export default SubscriptionThankyou