import React from 'react'
import Carousel from '../../components/SlickSlider/Carousel'
import {
    Section,
    Container,
} from '../../components/Grid'
import SectionHeading from '../../components/Content/SectionHeading.js'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'
import { connect } from 'react-redux'
import { fetchBrands } from '../../actions'
import { BASE_URL } from '../../paths'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import LazyLoad from 'react-lazyload';

class BrandCarousel extends React.Component {

    componentDidMount(){
        this.props.fetchBrands();       
    }

    renderList(){
        return this.props.brands.map((brand, index) => {
            return(
                <div className="bc_item" key={index}>
                    <Link to={`/${brand.search_name}`}>
                        <ImageScaleOnHover>
                            {
                                index > 3 && (
                                    <LazyLoad>
                                        <figure>                                    
                                            <img className="bc_image" src={`${BASE_URL}${brand.image}`} alt="" />
                                        </figure>
                                    </LazyLoad> 
                                )
                            }
                            {
                                index <= 3 && (
                                    <figure>                                    
                                        <img className="bc_image" src={`${BASE_URL}${brand.image}`} alt="" />
                                    </figure>
                                )
                            }
                                                       
                        </ImageScaleOnHover>                        
                        <img className="bc_logo" src={`${BASE_URL}${brand.logo_path}`} alt={brand.title}/>
                    </Link>                    
                </div>
            )
        })
    }

    render(){

        if(_.isEmpty(this.props.brands)){return <></>}
        return(
            <Section className="bc_slider-section bc_brands">
                <Container>
                    <SectionHeading text={this.props.title} link={this.props.link ? this.props.link : null} />
                    <Carousel>
                        {this.renderList()}
                    </Carousel>
                </Container>
            </Section>
        )
    }
}

const mapStateToProps = state => {
    return {
        brands: Object.values(state.brandsState)
    }
}

export default connect(mapStateToProps, {
    fetchBrands
})(BrandCarousel)