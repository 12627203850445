import React from 'react'
import Button from '../Buttons/Button'

class BannerText extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            subHeading: this.props.props.subHeading,
            heading: this.props.props.heading,
            buttonText: this.props.props.buttonText,
            buttonLink: this.props.props.buttonLink
        }
    }
    render() {
        return(            
            <div className="bc_banner-text">
                <div className="bc_content">
                    <h3 className="themegold">{this.state.subHeading}</h3>
                    <h2 className="white light">{this.state.heading}</h2>
                    <Button 
                        text={this.state.buttonText} 
                        url={this.state.buttonLink}
                    />
                </div>
            </div>
        )
    }
}

export default BannerText