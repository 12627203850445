import React from 'react'
import ReactDOM from 'react-dom'
import Loader from 'react-loader-spinner'

class LoaderSpinner extends React.Component {
    render(){
        return(
            ReactDOM.createPortal(
                <div className="bc_loader">
                    <Loader
                    type="TailSpin"
                    color="#B39E64"
                    height={100}
                    width={100}
                    // timeout={3000} //3 secs
                    />
                </div>,
                document.querySelector("#loader")
            )            
        )
    }
}

export default LoaderSpinner