import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import nextArrowIcon from '../../assets/img/Arrow-Right.svg'

class ProductImageCarousel extends React.Component {

    next = () => {
        this.slider.slickNext();
    }

    render() {

        const settings = {
            infinite: true,
            respondTo: 'min',
            speed: 750,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            easing: 'cubic-bezier(0.85, 0, 0.15, 1)',
            pauseOnHover: false,
            dots: true
        }

        return (
            <div className="bc_carousel_gallery">
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.props.children}
                </Slider>               
            </div>
        )
    }
} 

export default ProductImageCarousel