import React from 'react'
import Carousel from '../../components/SlickSlider/Carousel'
import {
    Section,
    Container,
} from '../../components/Grid'
import SectionHeading from '../../components/Content/SectionHeading.js'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'
import { connect } from 'react-redux'
import { fetchBlogs } from '../../actions'
import { BASE_URL } from '../../paths'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import LazyLoad from 'react-lazyload';

class BlogsCarousel extends React.Component {

    componentDidMount(){
        this.props.fetchBlogs();       
    }

    renderList(){
        return this.props.blogs.map((item, index) => {
            return(
                <div className="bc_item" key={index}>
                    <Link to={`/blog/${item.search_name}`}>
                        <ImageScaleOnHover>
                            {
                                index > 3 && (
                                    <LazyLoad>
                                        <figure>
                                            <img className="bc_image" src={`${BASE_URL}${item.image_preview ? item.image_preview : item.image}`} alt="" />
                                        </figure>
                                    </LazyLoad>
                                )
                            }
                            {
                                index <= 3 && (
                                    <figure>
                                        <img className="bc_image" src={`${BASE_URL}${item.image_preview ? item.image_preview : item.image}`} alt="" />
                                    </figure>
                                )
                            }
                            
                        </ImageScaleOnHover>
                        
                        <div className="bc_bottom-title">
                            <h4>
                                <span style={{textTransform: 'upperCase'}}>{item.title}</span>
                            </h4>
                        </div>
                    </Link>                    
                </div>
            )
        })
    }

    render(){

        if(_.isEmpty(this.props.blogs)){return <></>}

        return(
            <Section className="bc_slider-section bc_less-height bc_section-home-blog-carousel">
                <Container>
                    <SectionHeading text={this.props.title} />
                    <Carousel>
                        {this.renderList()}
                    </Carousel>
                </Container>
            </Section>
        )
    }
}

const mapStateToProps = state => {
    return {
        blogs: Object.values(state.blogsState)
    }
}

export default connect(mapStateToProps, {
    fetchBlogs
})(BlogsCarousel)