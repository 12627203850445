import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { 
    fetchBrands
} from '../../actions'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import TopBanner from '../../components/Banner/TopBanner'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import heroImg from '../../assets/img/hero.png'
import { BASE_URL } from '../../paths'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'
import Filter from '../../components/Filters/Filter'

class BrandsAll extends React.Component {

    componentDidMount(){
        this.props.fetchBrands()
    }

    renderList(){
        return this.props.data.map((item, index) => {
            return(
                <Grid col="4" key={index}>
                    <div className="bc_item">
                        <Link to={`/${item.search_name}`}>
                            <ImageScaleOnHover>
                                <figure>
                                    <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" />
                                </figure>
                            </ImageScaleOnHover>
                            
                            <img className="bc_logo" src={`${BASE_URL}${item.logo_path}`} alt={`${item.title} Logo`}  />
                        </Link>
                    </div>
                </Grid>
            )
        })
    }

    renderPageContent() {
        return(
            <React.Fragment>

                <TopBanner 
                    img={heroImg}
                    heading="Premium bathroom brands indulging you in the essence of quality design" 
                    subHeading="Featured Brands" 
                />

                <OnScrollAnimation>
                    <Section className="bc_product-item-section">
                        <Filter />
                        <Container>
                            <Row>
                                {this.renderList()}
                            </Row>
                        </Container>
                        
                    </Section>
                </OnScrollAnimation>

            </React.Fragment>
        )
    }

    render() {
        if(!this.props.data){
            return <></>
        }
        return(
            <React.Fragment>

                <Helmet>
                    <title>Featured Brands - Bathroom Collective</title>
                    <meta name="description" content="Bathroom Collective indulges consumers in the essence of quality design through the showcasing of premium local and international brands from hansgrohe, AXOR, Jeeves, Geberit, Duravit, Gio and Stiebel Eltron." />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                <Nav>{this.renderPageContent()}</Nav>        

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: Object.values(state.brandsState)
    }
}

export default connect(mapStateToProps, {
    fetchBrands
})(BrandsAll)