import _ from 'lodash'
import {
    FETCH_BRANDS,
    FETCH_BRAND
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type){
        case FETCH_BRANDS:
            return { ...state, ..._.mapKeys(action.payload, 'search_name') }
        case FETCH_BRAND:
            return { ...state, [action.payload.search_name]: action.payload }
        default:
            return state
    }
}