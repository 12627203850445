import { 
    REGISTER,
    SIGNUP_ERROR
} from '../actions/types'

const INITIAL_STATE = {
    success: null,
    message: null,
    errors: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case REGISTER:
            return {
                ...state,
                success: true,
                message: 'Registration Complete',
                errors: null
            }
        case SIGNUP_ERROR:
            return {
                ...state,
                success: null,
                message: action.payload,
                errors: true
            }
            
        default:
            return state
    }
}