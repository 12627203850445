import React from 'react'
import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll';
// https://daneden.github.io/animate.css/

class OnScrollAnimation extends React.Component {
    render(){
        return(
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                {this.props.children}
            </ScrollAnimation>
        )
    }
}

export default OnScrollAnimation