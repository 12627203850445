import {
    FETCH_CATEGORY,
    FETCH_BRAND_CATEGORY_PRODUCTS
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type){
        case FETCH_CATEGORY:
            return { ...state, [action.payload.searchName]: action.payload.data }

        case FETCH_BRAND_CATEGORY_PRODUCTS:
                return { ...state, [action.payload.searchName]: action.payload.data }
        default:
            return state
    }
}