import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import heroImg from '../../assets/img/hero.png'
import TopBanner from '../../components/Banner/TopBanner'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import {
    fetchLocations
} from '../../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../../paths'

class Contact extends React.Component {

    componentDidMount(){
        this.props.fetchLocations()
    }

    renderList(){
        return this.props.locations.map((item, index) => {
            return (
                <Grid col="4" key={index}>
                    <div className="bc_item" id={`bc_${item.postal_adrress}`}>
                        <a 
                            href={`https://www.google.com/maps/place/${item.street_number} ${item.street_address} ${item.suburb}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className="bc_item_image" src={`${BASE_URL}${item.image_large}`} alt="" />
                                <div className="bc_item-text">
                                <h2 className="bc_title">{item.title}</h2>
                                <p>
                                    <strong>Contact:</strong>
                                    <br />
                                    <a href={`tel:${item.phone}`}>
                                        {item.phone}
                                    </a>
                                </p>
                                <p>
                                    <strong>Email:</strong>
                                    <br />
                                    <a href={`mailto:${item.email}`}>
                                        {item.email}
                                    </a>                                
                                </p>
                                <p>
                                    <strong>Address:</strong>

                                    <br />

                                    <a 
                                        href={`https://www.google.com/maps/place/${item.street_number} ${item.street_address} ${item.suburb}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >

                                        {item.street_number} {item.street_address}, {item.suburb}

                                    </a>
                                </p>
                                
                            </div>
                        </a>
                    </div>
                </Grid>
            )
        })        
    }

    renderPageContent(){
        return (
            <>
                <TopBanner 
                    img={heroImg}
                    heading="Get in touch with any one of the Cape Plumbing & Bathroom stores nearest to you." 
                    subHeading="Contact Us"
                />
                <OnScrollAnimation>
                    <Section className="bc_contact-section">
                        <Container>
                            <Row>
                                {this.renderList()}
                            </Row>
                        </Container>
                        
                    </Section>
                </OnScrollAnimation>
            </>
        )
    }

    render() {
        if(!this.props.locations){
            return <></>
        }
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Contact Us</title>
                    <meta name="description" content="Contact us. Get in touch with any one of the Cape Plumbing & Bathrooms stores nearest to you." />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                <Nav>{this.renderPageContent()}</Nav>          
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        locations: state.locationsState.locations
    }
}

export default connect(mapStateToProps, {
    fetchLocations
})(Contact)