import {
    FETCH_SECTION
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case FETCH_SECTION:
            return {
                ...state, [action.payload.type]: action.payload
            }
        default:
            return state
    }
}