import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom'
import SmallHeading from '../../components/Content/SmallHeading'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'
import {
    signUp
} from '../../actions'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import AuthForm from './AuthForm'
import Loader from '../../components/ui/Loader'
import Popup from '../../components/Popups/Popup'
import Button from '../../components/Buttons/Button'
import closeImg from '../../assets/img/iconmonstr-x-mark-9-240.png'

class Register extends React.Component {

    state = {
        loader: false,
        popup: false
    }

    componentDidMount(){
        window.scroll(0, 0)
    }

    componentWillUnmount(){
        this.setState({
            loader: false,
            popup: false
        })
    }

    closePopup = () => {
        this.setState({
            popup:false,
        })
    }

    onSubmit = formValues => {
        this.setState({
            loader: true
        })
        setTimeout(() => {
            if(this.props.signUp(formValues)){
                this.setState({
                    loader: false,
                    popup: true
                })
            }
        }, 1000);
        window.scrollTo(0, 0)
    }

    renderPageContent(){
        return(
            <OnScrollAnimation>
                
                <SmallHeading heading="Signup" />

                {
                    !this.props.isSignedIn &&
                    <AuthForm 
                        heading="Almost There!" 
                        onSubmit={this.onSubmit}
                    />
                }
                {
                    this.props.isSignedIn &&
                    <Section className="bc_form-section">
                        <Container className="bc_small">
                            <Row>                                
                                <Grid className="bc_textAlign-center">
                                    <h2>You are Registered!</h2>
                                    <br />
                                    <Button
                                        text="Explore"
                                        url="/"
                                    />
                                </Grid>         
                            </Row>                            
                        </Container>
                    </Section>
                }

            </OnScrollAnimation>
        )
    }

    render() {

        if(this.state.popup && this.props.register.success){
            return (
                <Popup>
                    <button className="bc_popup-close-btn" onClick={this.closePopup}>
                        <img src={closeImg} alt="" />
                    </button>
                    <h2>Registration Complete. You're signed up.</h2>
                    <div className="bc_popup-flex">
                        <Button
                            text="Explore"
                            url="/"
                        />
                    </div>
                </Popup>
            )
        }

        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Register</title>
                    <meta name="description" content="Sign in or create an account and start your journey with Bathroom Collective." />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                { this.state.loader && <Loader /> }

                <Nav>{this.renderPageContent()}</Nav>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSignedIn: state.authState.isSignedIn,
        register: state.registerState
    }
}

export default connect(mapStateToProps, {
    signUp
})(Register)