import React from 'react'

class PageNotFound404 extends React.Component {
    render(){
        return (
            <h1>404</h1>
        )
    }
} 

export default PageNotFound404