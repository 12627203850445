import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { 
    fetchProducts
} from '../../actions'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import TopBanner from '../../components/Banner/TopBanner'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import heroImg from '../../assets/img/hero.png'
import { BASE_URL } from '../../paths'
import Filter from '../../components/Filters/Filter'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'
import {Pagination} from 'react-laravel-paginex'

class ProductsAll extends React.Component {

    componentDidMount(){
        if(!this.props.data){
            this.props.fetchProducts(null)            
        }
    }

    componentDidUpdate(){
        window.scrollTo(0, 0)
    }

    renderList(){
        return this.props.data.data.map(item => {
            if(item.brand){
                return(
                    <Grid col="4" key={item.id} className="bc_item">
                        <Link to={`/${item.brand.search_name}/${item.category.search_name}`}>
                            <ImageScaleOnHover>
                                <figure>
                                    <img src={`${BASE_URL}${item.image}`} alt="" className="bc_image" />
                                </figure>
                            </ImageScaleOnHover>
                            
                            <h4>
                                <span style={{textTransform: 'upperCase'}}>{item.brand.title}</span> {item.title}
                            </h4>
                        </Link>
                    </Grid>
                )
            }            
        })
    }

    renderPageContent() {
        return(
            <React.Fragment>

                <TopBanner 
                    img={heroImg}
                    heading="Beautifully sourced bathroom products and accessories" 
                    subHeading="Products"
                />

                <OnScrollAnimation>
                    <Section className="bc_product-item-section">
                        <Filter />
                        <Container>
                            <Row>
                                {this.props.data.data && this.renderList()}
                            </Row>
                        </Container>
                        <Container className="bc_pagination-container">
                            <Row>
                                <Pagination changePage={this.props.fetchProducts} data={this.props.data} containerClass="bc_pagination" />
                            </Row>
                        </Container>
                    </Section>
                </OnScrollAnimation>

            </React.Fragment>
        )
    }

    render() {

        if(!this.props.data){return <></>}

        const ldJson = {
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [
                {
                    "@type": "ListItem", 
                    "position": 1, 
                    "name": "Home",
                    "item": "https://bathroomcollective.co.za"  
                },
                {
                    "@type": "ListItem", 
                    "position": 2, 
                    "name": "All Products",
                    "item": "https://bathroomcollective.co.za/products"  
                }
            ]
        }
        
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Products</title>
                    <meta name="description" content="Bathroom Collective provides design inspiration through the showcasing of beautifully sourced local and international bathroom products and accessories." />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <link rel="canonical" href={window.location} />
                </Helmet>

                <Nav>{this.renderPageContent()}</Nav>        

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.productsState.data
    }
}

export default connect(mapStateToProps, {
    fetchProducts
})(ProductsAll)