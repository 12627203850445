import {
    FETCH_COLLECTION_QTY,
    ADD_COLLECTION_ITEM,
    REMOVE_COLLECTION_ITEM,
    RESET_COLLECTION
} from '../actions/types'

//initial state object before any items has been added to the cart
const intialState = {
    totalQuantity: 0,
    items: {}
}

export default (state = intialState, action) => {
    switch(action.type){
        case ADD_COLLECTION_ITEM:

            //checks if item exists in collection state
            if(state.items[action.payload.id]){
                return {
                    ...state
                }
            }

            //returns a new state object with the added item / updated quantity of existing item in the cart
            return{
                ...state,
                totalQuantity: state.totalQuantity + 1,
                items: {
                    ...state.items,
                    [action.payload.id]: {
                        key: action.payload.id,
                        title: action.payload.title,
                        search_name: action.payload.search_name,
                        image: action.payload.image,
                        brand: action.payload.brand,
                        category: action.payload.category
                    }
                }
            }  
        
        case REMOVE_COLLECTION_ITEM:
            //checks if item exists in collection state
            if(state.items[action.payload]){
                return{
                    ...state,
                    totalQuantity: state.totalQuantity - 1,
                    items: {
                        ...state.items,
                        [action.payload]: null
                    }
                }  
            }

            return state

        case RESET_COLLECTION:
            return intialState

        //returns the total quantity of all items in the cart
        case FETCH_COLLECTION_QTY:
            return {
                ...state
            }
        default:
            return state
    }
}