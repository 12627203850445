import React from 'react'
import Carousel from '../../components/SlickSlider/Carousel'
import {
    Section,
    Container,
} from '../../components/Grid'
import SectionHeading from '../../components/Content/SectionHeading.js'
import { connect } from 'react-redux'
import { fetchCategoriesWithOneProduct } from '../../actions'
import { BASE_URL } from '../../paths'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'

import LazyLoad from 'react-lazyload';

class ProductCategoryCarousel extends React.Component {

    componentDidMount(){
        if(!this.props.data.length > 0){
            this.props.fetchCategoriesWithOneProduct()
        }        
    }

    renderList(){
        return this.props.data.map((item, index) => {
            return(
                <div className="bc_item" key={index}>
                    <Link to={`/products/${item.search_name}`}>
                        <ImageScaleOnHover>
                            {
                                index > 3 && (
                                    <LazyLoad>
                                        <figure>
                                            <img className="bc_image" src={`${BASE_URL}${item.products.image}`} alt="" />
                                        </figure>
                                    </LazyLoad>
                                )
                            }        
                            {
                                index <= 3 && (
                                    <figure>
                                        <img className="bc_image" src={`${BASE_URL}${item.products.image}`} alt="" />
                                    </figure>
                                )
                            }                    
                        </ImageScaleOnHover>                        
                        <div className="bc_bottom-title"><h4>{item.title}</h4></div>
                    </Link>                    
                </div>
            )
        })
    }

    render(){

        if(_.isEmpty(this.props.data)){return <></>}

        return(
            <Section className="bc_slider-section bc_less-height bc_section-home-products">
                <Container>
                    <SectionHeading text={this.props.title} link={this.props.link ? this.props.link : null}  />
                    <Carousel>
                        {this.renderList()}
                    </Carousel>
                </Container>
            </Section>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: Object.values(state.categoriesState)
    }
}

export default connect(mapStateToProps, {
    fetchCategoriesWithOneProduct
})(ProductCategoryCarousel)