import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import MakeAnAppointment from '../Forms/MakeAnAppointment'
import SmallHeading from '../../components/Content/SmallHeading'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {resetCollection} from '../../actions'
import { BASE_URL } from '../../paths'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'

class Collections extends React.Component {

    handleResetCollection = () => {
        this.props.resetCollection()
    }

    renderList(){
        return this.props.data.map((item, index) => {
            if(item === null){return <></>}
            return (
                <Grid col="3" className="bc_item bc_collection-item" key={index}>
                    <Link to={`/${item.brand.search_name}/${item.category.search_name}`}>
                        <img src={`${BASE_URL}${item.image}`} alt=""  />
                        <h3>
                            {item.title}
                            <span>By: {item.brand.title}</span>
                        </h3>
                    </Link>
                </Grid>
            )
        })        
    }

    renderPageContent(){
        return(
            <OnScrollAnimation>
                <SmallHeading heading="My Collection" />

                <Section className="bc_product-item-section">
                    <Container>
                        <Row>
                            <Grid>
                                <h2 className="themegold">Update your Collection or send it to our friendly staff.</h2>
                                <p>Click on the "Save to My Collection" button on all images & products that you love and are interested in and create your "Own Collection". Once you complete the form and submit your details, a sales consultant will be in touch with a quote and a personal invitation to visit one of our showrooms near you.</p>
                            </Grid>
                        </Row>
                    </Container>
                </Section>
                
                <Section className="bc_product-item-section">
                
                    {
                        this.props.data.length > 0 && (
                            <Container>
                                <Row>
                                    <Grid>
                                        <div className="bc_collection-contoll">
                                            <button onClick={this.handleResetCollection}>CLEAR ALL</button>
                                        </div>
                                    </Grid>
                                </Row>
                            </Container>
                        )
                    }
                    
                    <Container>
                        <Row>
                            {this.renderList()}
                        </Row>
                    </Container>
                </Section>

                <MakeAnAppointment collection={this.props.data} />
            </OnScrollAnimation>
        )
    }
    render() {
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | My Collection</title>
                    <meta name="description" content="Bathroom Collective's 'My Collection' allows you to update and save images and products, send your 'Collection' to our friendly staff or make an appointment." />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                
                <Nav>{this.renderPageContent()}</Nav>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        data: Object.values(state.collectionState.items)
    }
}

export default connect(mapStateToProps, {
    resetCollection
})(Collections)