import React from 'react'
//cant use BrowserRouter with our own history object(which we recreate in order to get access to it for navigation)
import {
    Router,
    Switch,
    Route
} from "react-router-dom"
import { connect } from 'react-redux'
import PrivateRoute from './PrivateRoute'
import history from '../history'
import Home from './Home/Home'
import Brands from './Brands/Brands'
import Products from './Products/Products'
import BrandProducts from './Products/BrandProducts'
import Collections from './Collections/Collections'
import Contact from './Contact/Contact'
// import Cart from './Cart/Cart'
import Profile from './Auth/Profile'
import SignIn from './Auth/SignIn'
import Register from './Auth/Register'
import PasswordForgot from './Auth/PasswordForgot'
import PasswordReset from './Auth/PasswordReset'
import PasswordSuccess from './Auth/PasswordSuccess'
// import Promotion from './Promotions/Promotion'
// import Bundle from './Promotions/Bundle'
import BrandCategory from './Brands/BrandCategory'
import BrandsAll from './Brands/BrandsAll'
import ProductsAll from './Products/ProductsAll'
import Blog from './Blogs/Blog'
// import Checkout from './Cart/Checkout'
// import Payfast from './Cart/payfast'
import ScrollToTop from './ScrollToTop'
// import Cancel from './Cart/Cancel'
// import Success from './Cart/Success'
import Loader from '../components/ui/Loader'
// import Bundles from './Bundles/Bundles'
// import Bundle from './Bundles/Bundle'
import Enquire from './Enquire/Enquire'
import EnquireThankyou from './Enquire/EnquireThankyou'
import MakeAnAppointmentThankyou from './Collections/MakeAnAppointmentThankyou'
import SubscriptionThankyou from './Subscription/SubscriptionThankyou'
import PageNotFound from './PageNotFound/PageNotFound'
import PageNotFound404 from './PageNotFound/PageNotFound404'
import TermsAndConditions from './TermsAndConditions/TermsAndConditions'

class App extends React.Component {

    state={
        loader: true
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                loader: false
            })
        }, 1000);
    }

    render() {
        return (
            <Router history={history}>  
                <ScrollToTop />   
                {/* { this.state.loader && <Loader /> } */}
                <Switch>

                    <Route 
                        exact 
                        path="/"
                        component={Home}
                    />

                    {/* <Route 
                        exact 
                        path="/cart"
                        component={Cart}
                    /> */}

                    {/* <PrivateRoute 
                        exact 
                        path="/checkout"
                        component={Checkout}
                    /> */}

                    {/* <PrivateRoute 
                        exact 
                        path="/payfast"
                        component={Payfast}
                    /> */}

                    {/* <PrivateRoute 
                        exact 
                        path="/cancel"
                        component={Cancel}
                    /> */}

                    {/* <PrivateRoute 
                        exact 
                        path="/success"
                        component={Success}
                    /> */}

                    <Route 
                        exact 
                        path="/products"
                        component={ProductsAll}
                    />

                    {/* <Route 
                        exact 
                        path="/promotion"
                        component={Promotion}
                    /> */}

                    {/* <Route 
                        exact 
                        path="/promotion/:searchName"
                        component={Bundle}
                    /> */}

                    {/* <Route 
                        exact 
                        path="/bundles"
                        component={Bundles}
                    /> */}

                    {/* <Route 
                        exact 
                        path="/bundle/:searchName"
                        component={Bundle}
                    /> */}

                    <Route 
                        exact 
                        path="/products/:searchName"
                        component={Products}
                    />

                    <Route 
                        exact 
                        path="/collections"
                        component={Collections}
                    />

                    <Route 
                        exact 
                        path="/collections/thankyou"
                        component={MakeAnAppointmentThankyou}
                    />
                    <Route 
                        exact 
                        path="/subscription/thankyou"
                        component={SubscriptionThankyou}
                    />

                    <Route 
                        exact 
                        path="/contact"
                        component={Contact}
                    />

                    <Route 
                        exact 
                        path="/enquire/thankyou"
                        component={EnquireThankyou}
                    />

                    <Route 
                        exact 
                        path="/enquire/:productSearchName"
                        component={Enquire}
                    />

                    <PrivateRoute 
                        exact 
                        path="/profile"
                        component={Profile}
                    />

                    <Route 
                        exact 
                        path="/signin"
                        component={SignIn}
                    />

                    <Route 
                        exact 
                        path="/register"
                        component={Register}
                    />

                    <Route 
                        exact 
                        path="/password/forgot"
                        component={PasswordForgot}
                    />

                    <Route 
                        exact 
                        path="/password/reset/:resetToken"
                        component={PasswordReset}
                    />

                    <Route 
                        exact 
                        path="/password/success"
                        component={PasswordSuccess}
                    />

                    <Route  
                        path="/brands"
                        exact
                        component={BrandsAll}
                    />

                    <Route 
                        exact 
                        path="/blog/:searchName"
                        component={Blog}
                    />

                    <Route 
                        exact 
                        path="/terms-and-conditions"
                        component={TermsAndConditions}
                    />

                    <Route 
                        exact 
                        path="/page-not-found"
                        component={PageNotFound404}
                    />

                    <Route  
                        path="/:searchName"
                        exact
                        component={Brands}
                    />

                    <Route  
                        path="/:searchName/products"
                        exact
                        component={BrandProducts}
                    />

                    <Route 
                        exact 
                        path="/:brandSearchName/:categorySearchName"
                        component={BrandCategory}
                    />

                    



                    <Route 
                        component={PageNotFound}
                    />

                </Switch>
            </Router>
        )
    }
}

const MapStateToProps = state => ({
    isSignedIn: state.authState.isSignedIn
})

export default connect(MapStateToProps)(App)