import React from 'react'
import ReactDOM from 'react-dom'

class Popup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVerified: false
        }
    }

    onclickClosePopup = event => {
        this.setState({
            isVerified: true
        })
    }

    render() {
        return(
            ReactDOM.createPortal(
                <React.Fragment>
                    <div className={`bc_popup-container ${this.props.className ? this.props.className : ''}`}>
                        <div className="bc_overlay-light"></div>
                        <div className="bc_popup-panel">
                            {this.props.children}
                        </div>
                    </div>
                </React.Fragment>,
                document.querySelector("#popup")
            )            
        )      
    }
} 

export default Popup